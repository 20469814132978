import React, { Component } from "react";
import State from "../../../utils/State.js";
import GrowthLineChart from "../../elements/data/GrowthLineChart.js";
import GrowthResume from "../../elements/data/GrowthResume.js";
import Design from "../../../utils/design.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import DateSelector from "../../elements/selectors/DateSelector.js";
import Loader from "../../elements/utils/Loader.js";

const GROUPED_RESSOURCES = {
  starred: [
    {
      name: "revenue",
      path: "api/business/revenue_growth",
      params: {},
      meta: { title: "Revenue", unit: "€" }
    },
    {
      name: "creditSpent",
      path: "api/business/credit_spent_growth",
      params: {},
      meta: { title: "Credits" }
    },
    {
      name: "starredActiveUsers",
      path: "api/business/active_users",
      params: {},
      meta: { title: "Active Users" }
    },
    {
      name: "starredActiveClubSubscriptions",
      path: "api/business/active_subscriptions",
      params: { type: "club" },
      meta: { title: "Active Clubs", aggregationFunc: "last" }
    },
    {
      name: "starredActiveFamilySpaceSubscriptions",
      path: "api/business/active_subscriptions",
      params: { type: "family_space" },
      meta: { title: "Active Family Spaces", aggregationFunc: "last" }
    },
    {
      name: "starredDownloads",
      path: "api/business/download_growth",
      params: {},
      meta: { title: "Downloads" }
    },
    {
      name: "starredRegistrations",
      path: "api/business/registration_growth",
      params: {},
      meta: { title: "Registrations" }
    },
    {
      name: "starredActivations",
      path: "api/business/activation_growth",
      params: {},
      meta: { title: "Activations" }
    },
    {
      name: "starredPwbExportRate",
      path: "api/business/pwb_process_rate_growth",
      params: {},
      meta: { title: "PWB Export %", unit: "%", aggregationFunc: "avg" }
    }
  ],
  revenue: [
    {
      name: "revenue",
      path: "api/business/revenue_growth",
      params: {},
      meta: { title: "All", unit: "€" }
    },
    {
      name: "businessPlan",
      path: "api/business/expected_revenue",
      params: {},
      meta: { title: "Vs BP", unit: "€" }
    },
    {
      name: "newRevenue",
      path: "api/business/revenue_growth",
      params: { type: "new_business" },
      meta: { title: "New Business", unit: "€" }
    },
    {
      name: "repeatRevenue",
      path: "api/business/revenue_growth",
      params: { type: "repeat_business" },
      meta: { title: "Repeat Business", unit: "€" }
    },
    {
      name: "abroadRevenue",
      path: "api/business/revenue_growth",
      params: { is_abroad: true },
      meta: { title: "Abroad", unit: "€" }
    },
    {
      name: "germanyRevenue",
      path: "api/business/revenue_growth",
      params: { country: "DE" },
      meta: { title: "Germany", unit: "€" }
    },
    {
      name: "androidRevenue",
      path: "api/business/revenue_growth",
      params: { os_name: "android" },
      meta: { title: "Android", unit: "€" }
    },
    {
      name: "iosRevenue",
      path: "api/business/revenue_growth",
      params: { os_name: "ios" },
      meta: { title: "IOS", unit: "€" }
    },
    {
      name: "webRevenue",
      path: "api/business/revenue_growth",
      params: { os_name: "web" },
      meta: { title: "Web", unit: "€" }
    },
    {
      name: "discountRevenue",
      path: "api/business/revenue_growth",
      params: { is_discount: true },
      meta: { title: "Discount", unit: "€" }
    },
    {
      name: "paypalRevenue",
      path: "api/business/revenue_growth",
      params: { is_paypal: true },
      meta: { title: "Paypal", unit: "€" }
    },
    {
      name: "subscriptionRevenue",
      path: "api/business/revenue_growth",
      params: { is_subscription: true },
      meta: { title: "Subscriptions", unit: "€" }
    }
  ],
  product_printouts: [
    {
      name: "printouts",
      path: "api/business/printouts_growth",
      params: { os_name: "all" },
      meta: { title: "All" }
    },
    {
      name: "printoutsPostcards",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "postcard" },
      meta: { title: "Postcards" }
    },
    {
      name: "printoutsPostcardVideos",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "video" },
      meta: { title: "Video options" }
    },
    {
      name: "printoutsPostcardMagnets",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "postcard_magnet" },
      meta: { title: "Postcards Magnet" }
    },
    {
      name: "printoutsSquareCardMagnets",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "square_premium_card_magnet" },
      meta: { title: "Square Cards Magnet" }
    },
    {
      name: "printoutsSquareCard",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "square_premium_card" },
      meta: { title: "Square Cards" }
    },
    {
      name: "printoutsGreetings",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "greeting_card" },
      meta: { title: "Greetings" }
    },
    {
      name: "printoutsPortraitCard",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "portrait_card" },
      meta: { title: "Portrait Cards" }
    },
    {
      name: "printoutsSetPostcards",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "set_of_postcards" },
      meta: { title: "Set Of Postcards" }
    },
    {
      name: "printoutsSetGreetings",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "set_of_greeting_cards" },
      meta: { title: "Set Of Greetings" }
    },
    {
      name: "printoutsSetOfPortraits",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "set_of_portrait_cards" },
      meta: { title: "Set Of Portraits" }
    },
    {
      name: "printoutsSquareAlbum",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "square_photobook" },
      meta: { title: "Square Albums" }
    },
    {
      name: "printoutsLandscapeAlbum",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "landscape_photobook" },
      meta: { title: "Landscape Albums" }
    },
    {
      name: "printoutsGazette",
      path: "api/business/printouts_growth",
      params: { os_name: "all", formats: "gazette" },
      meta: { title: "Gazettes" }
    }
  ],
  credits_spent: [
    {
      name: "creditSpent",
      path: "api/business/credit_spent_growth",
      params: {},
      meta: { title: "All" }
    },
    {
      name: "creditSpentPostcards",
      path: "api/business/credit_spent_growth",
      params: { formats: "postcard" },
      meta: { title: "Postcards" }
    },
    {
      name: "creditSpentVideo",
      path: "api/business/credit_spent_growth",
      params: { formats: "video" },
      meta: { title: "Video options" }
    },
    {
      name: "creditSpentMagnets",
      path: "api/business/credit_spent_growth",
      params: { formats: "postcard_magnet" },
      meta: { title: "Postcards Magnet" }
    },
    {
      name: "creditSpentSquareMagnets",
      path: "api/business/credit_spent_growth",
      params: { formats: "square_premium_card_magnet" },
      meta: { title: "Square Cards Magnet" }
    },
    {
      name: "creditSpentSquareCards",
      path: "api/business/credit_spent_growth",
      params: { formats: "square_premium_card" },
      meta: { title: "Square Cards" }
    },
    {
      name: "creditSpentGreetings",
      path: "api/business/credit_spent_growth",
      params: { formats: "greeting_card" },
      meta: { title: "Greetings" }
    },
    {
      name: "creditSpentPortraitCards",
      path: "api/business/credit_spent_growth",
      params: { formats: "portrait_card" },
      meta: { title: "Portrait Cards" }
    },
    {
      name: "creditSpentSet",
      path: "api/business/credit_spent_growth",
      params: {
        formats: "set_of_greeting_cards,set_of_postcards,set_of_portrait_cards"
      },
      meta: { title: "Sets" }
    },
    {
      name: "creditSpentSquareAlbum",
      path: "api/business/credit_spent_growth",
      params: { formats: "square_photobook" },
      meta: { title: "Square Albums" }
    },
    {
      name: "creditSpentLandscapeAlbum",
      path: "api/business/credit_spent_growth",
      params: { formats: "landscape_photobook" },
      meta: { title: "Landscape Albums" }
    },
    {
      name: "creditSpentGazette",
      path: "api/business/credit_spent_growth",
      params: { formats: "gazette" },
      meta: { title: "Gazettes" }
    }
  ],
  downloads: [
    {
      name: "downloads",
      path: "api/business/download_growth",
      params: {},
      meta: { title: "All" }
    },
    {
      name: "downloadsAndroid",
      path: "api/business/download_growth",
      params: { os_name: "android" },
      meta: { title: "Android" }
    },
    {
      name: "downloadsIos",
      path: "api/business/download_growth",
      params: { os_name: "ios" },
      meta: { title: "IOS" }
    }
  ],
  registrations: [
    {
      name: "registrations",
      path: "api/business/registration_growth",
      params: {},
      meta: { title: "All" }
    },
    {
      name: "registrationsAndroid",
      path: "api/business/registration_growth",
      params: { os_name: "android" },
      meta: { title: "Android" }
    },
    {
      name: "registrationsIos",
      path: "api/business/registration_growth",
      params: { os_name: "ios" },
      meta: { title: "IOS" }
    },
    {
      name: "registrationsWeb",
      path: "api/business/registration_growth",
      params: { os_name: "web" },
      meta: { title: "Web" }
    },
    {
      name: "registrationsPaid",
      path: "api/business/registration_growth",
      params: { acquisition_type: "paid" },
      meta: { title: "Paid" }
    },
    {
      name: "registrationsOrganic",
      path: "api/business/registration_growth",
      params: { acquisition_type: "organic" },
      meta: { title: "Organic" }
    },
    {
      name: "registrationsSEO",
      path: "api/business/registration_growth",
      params: { acquisition_type: "seo" },
      meta: { title: "SEO" }
    }
  ],
  activations: [
    {
      name: "activations",
      path: "api/business/activation_growth",
      params: {},
      meta: { title: "All" }
    },
    {
      name: "activationsAndroid",
      path: "api/business/activation_growth",
      params: { os_name: "android" },
      meta: { title: "Android" }
    },
    {
      name: "activationsIos",
      path: "api/business/activation_growth",
      params: { os_name: "ios" },
      meta: { title: "IOS" }
    },
    {
      name: "activationsWeb",
      path: "api/business/activation_growth",
      params: { os_name: "web" },
      meta: { title: "Web" }
    },
    {
      name: "activationsPaid",
      path: "api/business/activation_growth",
      params: { acquisition_type: "paid" },
      meta: { title: "Paid" }
    },
    {
      name: "activationsOrganic",
      path: "api/business/activation_growth",
      params: { acquisition_type: "organic" },
      meta: { title: "Organic" }
    },
    {
      name: "activationsSEO",
      path: "api/business/activation_growth",
      params: { acquisition_type: "seo" },
      meta: { title: "SEO" }
    },
    {
      name: "activationsSponsorized",
      path: "api/business/activation_growth",
      params: { is_sponsorized: true },
      meta: { title: "Sponsorized" }
    },
    {
      name: "activationsCemetery",
      path: "api/business/activation_growth",
      params: { is_activated_from_cemetery: true },
      meta: { title: "Cemetery" }
    }
  ],
  activation_rate: [
    {
      name: "activationRate",
      path: "api/business/activation_rate",
      params: {},
      meta: { title: "All", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "activationRateAndroid",
      path: "api/business/activation_rate",
      params: { os_name: "android" },
      meta: { title: "Android", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "activationRateIos",
      path: "api/business/activation_rate",
      params: { os_name: "ios" },
      meta: { title: "IOS", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "activationRateWeb",
      path: "api/business/activation_rate",
      params: { os_name: "web" },
      meta: { title: "Web", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "activationRatePaid",
      path: "api/business/activation_rate",
      params: { acquisition_type: "paid" },
      meta: { title: "Paid", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "activationRateOrganic",
      path: "api/business/activation_rate",
      params: { acquisition_type: "organic" },
      meta: { title: "Organic", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "activationRateSEO",
      path: "api/business/activation_rate",
      params: { acquisition_type: "seo" },
      meta: { title: "SEO", unit: "%", aggregationFunc: "avg" }
    }
  ],
  average_cart: [
    {
      name: "avgCart",
      path: "api/business/avg_cart",
      params: {},
      meta: { title: "All", unit: "€", aggregationFunc: "avg" }
    },
    {
      name: "avgCartNewBusiness",
      path: "api/business/avg_cart",
      params: { type: "new_business" },
      meta: { title: "New Business", unit: "€", aggregationFunc: "avg" }
    },
    {
      name: "avgCartRepeatBusiness",
      path: "api/business/avg_cart",
      params: { type: "repeat_business" },
      meta: { title: "Repeat Business", unit: "€", aggregationFunc: "avg" }
    },
    {
      name: "avgCartDiscountBusiness",
      path: "api/business/avg_cart",
      params: { is_discount: true },
      meta: { title: "Discount", unit: "€", aggregationFunc: "avg" }
    }
  ],
  pwb_export_rate: [
    {
      name: "pwbExportRate",
      path: "api/business/pwb_process_rate_growth",
      params: {},
      meta: { title: "All", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "pwbExportRatePostcard",
      path: "api/business/pwb_process_rate_growth",
      params: { product_family: "postcard" },
      meta: { title: "Postcards", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "pwbExportRateGazette",
      path: "api/business/pwb_process_rate_growth",
      params: { product_family: "gazette" },
      meta: { title: "Gazettes", unit: "%", aggregationFunc: "avg" }
    },
    {
      name: "pwbExportRatePhotobook",
      path: "api/business/pwb_process_rate_growth",
      params: { product_family: "photobook" },
      meta: { title: "Photobooks", unit: "%", aggregationFunc: "avg" }
    }
  ],
  acquisition_costs: [
    {
      name: "discoveryCosts",
      path: "api/business/cost_growth_discoveries",
      params: {},
      meta: { title: "Discovery Coupons", unit: "€" }
    },
    {
      name: "paidCosts",
      path: "api/business/cost_growth_paid_media",
      params: {},
      meta: { title: "Paid ($ x0.89)", unit: "€" }
    },
    {
      name: "googleCosts",
      path: "api/business/cost_growth_paid_media",
      params: { media_sources: "googleadwords_int" },
      meta: { title: "GGL ($ x0.89)", unit: "€" }
    },
    {
      name: "facebookCosts",
      path: "api/business/cost_growth_paid_media",
      params: { media_sources: "Facebook Ads" },
      meta: { title: "FB ($ x0.89)", unit: "€" }
    },
    {
      name: "appleCosts",
      path: "api/business/cost_growth_paid_media",
      params: { media_sources: "Apple Search Ads" },
      meta: { title: "APPL ($ x0.89)", unit: "€" }
    }
  ],
  others: [
    {
      name: "zendesk",
      path: "api/business/care_growth",
      params: {},
      meta: { title: "Zendesk" }
    },
    {
      name: "cpa",
      path: "api/business/cpa",
      params: {},
      meta: { title: "CPA ($ x0.89)", unit: "€", aggregationFunc: "avg" }
    },
    {
      name: "cpaFacebook",
      path: "api/business/cpa",
      params: { media_sources: "Facebook Ads" },
      meta: { title: "CPA FB ($ x0.89)", unit: "€", aggregationFunc: "avg" }
    },
    {
      name: "cpaGoogle",
      path: "api/business/cpa",
      params: { media_sources: "googleadwords_int" },
      meta: { title: "CPA GGL ($ x0.89)", unit: "€", aggregationFunc: "avg" }
    },
    {
      name: "cpaApple",
      path: "api/business/cpa",
      params: { media_sources: "Apple Search Ads" },
      meta: { title: "CPA APPL ($ x0.89)", unit: "€", aggregationFunc: "avg" }
    }
  ]
};

class KpiOverview extends Component {
  constructor(props) {
    super(props);

    let defaultPreset = Controler.defaultPreset();
    let state = new State(this).add("displayType", defaultPreset.displayType);

    for (let [category, ressources] of Object.entries(GROUPED_RESSOURCES)) {
      for (let ressource of ressources) {
        let curr_resssource = JSON.parse(JSON.stringify(ressource));
        curr_resssource = this.updateRessource(curr_resssource, defaultPreset);
        curr_resssource.params["export_type"] = "growth";
        state = state.addRessource(curr_resssource);
        curr_resssource = JSON.parse(JSON.stringify(ressource));
        curr_resssource.name += "_resume";
        curr_resssource.params["export_type"] = "growth_resume";
        state = state.addRessource(curr_resssource);
      }
    }

    this.state = state;
  }

  updateRessource = (ressource, preset) => {
    ressource.params["aggregation_by"] = preset.aggregationBy;
    ressource.params["original_from_date"] = preset.originalFromDate;
    ressource.params["original_until_date"] = preset.originalUntilDate;
    ressource.params["baseline_from_date"] = preset.baselineFromDate;
    ressource.params["baseline_until_date"] = preset.baselineUntilDate;
    ressource.meta.interestFromDate = preset.interestFromDate;
    ressource.meta.interestUntilDate = preset.interestUntilDate;
    return ressource;
  };

  componentDidMount() {
    this.state.fetchAndSet();
  }

  kpiBuilder = (kpiName, title) => {
    let growthLineRessource = this.state.get().ressources[kpiName];
    let resumeRessource = this.state.get().ressources[`${kpiName}_resume`];

    if (!resumeRessource.isLoaded || !growthLineRessource.isLoaded) {
      return (
        <div key={kpiName} className="kpi_wrapper">
          <Loader />
        </div>
      );
    }

    return (
      <KPIChart
        key={kpiName}
        title={title}
        unit={growthLineRessource.meta.unit}
        lineChartData={growthLineRessource.data}
        resumeData={resumeRessource.data}
        interestFromDate={growthLineRessource.meta.interestFromDate}
        interestUntilDate={growthLineRessource.meta.interestUntilDate}
        aggregationBy={growthLineRessource.params["aggregation_by"]}
        displayType={this.state.get().displayType}
        aggregationFunc={growthLineRessource.meta.aggregationFunc}
      />
    );
  };

  get_os_selector_button = (category_name, os_name) => {
    let ressource_name = GROUPED_RESSOURCES[category_name][0].name;
    return (
      <button
        type="button"
        className={
          this.state.get().ressources[ressource_name].params.os_name === os_name
            ? "active"
            : "inactive"
        }
        onClick={() => {
          this.state.set(s => {
            for (let ressource of GROUPED_RESSOURCES[category_name]) {
              s.ressources[ressource.name].params["os_name"] = os_name;
              s.ressources[ressource.name + "_resume"].params[
                "os_name"
              ] = os_name;
            }
          });
        }}
      >
        {os_name}
      </button>
    );
  };

  get_os_selector_buttons = category_name => {
    if (category_name != "product_printouts") {
      return null;
    }
    return (
      <div className="os-selector">
        {this.get_os_selector_button(category_name, "all")}
        {this.get_os_selector_button(category_name, "android")}
        {this.get_os_selector_button(category_name, "ios")}
        {this.get_os_selector_button(category_name, "web")}
      </div>
    );
  };

  render() {
    let body = [];
    for (let [category, ressources] of Object.entries(GROUPED_RESSOURCES)) {
      body.push(
        <div key={`${category}_title`} className="grid_layout">
          <h2 key={`${category}_type`}>{category.replaceAll(/_/gi, " ")}</h2>
          {this.get_os_selector_buttons(category)}
        </div>
      );
      body.push(
        <div key={`${category}_charts`} className="grid_layout">
          {ressources.map(x => this.kpiBuilder(x.name, x.meta.title))}
        </div>
      );
    }
    return (
      <div className="page business kpi_overview">
        <Controler
          callback={x => {
            this.state.set(s => {
              for (let [category, ressources] of Object.entries(
                GROUPED_RESSOURCES
              )) {
                for (let ressource of ressources) {
                  this.updateRessource(s.ressources[ressource.name], x);
                }
              }
              s["displayType"] = x["displayType"];
            });
          }}
        />
        {body}
      </div>
    );
  }
}

export default KpiOverview;

class Controler extends Component {
  constructor(props) {
    super(props);
    this.state = Controler.defaultPreset();
    this.currentPreset = Controler.defaultPreset();
  }

  static defaultPreset() {
    return Controler.timePreset("last_7days_vs_last_year");
  }

  static timePreset(preset_agg_date) {
    let today = ValueAdaptor.utcToday();
    let prevYear = ValueAdaptor.addYears(today, -1);
    let yesterday = ValueAdaptor.addDays(today, -1);
    let beginningOfMonth = ValueAdaptor.truncateUTCMonthDate(yesterday);
    let nextMonth = ValueAdaptor.addMonths(beginningOfMonth, 1);
    let prevMonth = ValueAdaptor.addMonths(beginningOfMonth, -1);

    switch (preset_agg_date) {
      case "today_vs_last_year":
        let preset = {
          activePreset: "today_vs_last_year",
          displayType: "cumulated",
          aggregationBy: "hour",
          originalFromDate: today,
          originalUntilDate: ValueAdaptor.addDays(today, 1),
          interestFromDate: today,
          interestUntilDate: ValueAdaptor.addDays(today, 1)
        };
        preset["baselineFromDate"] = prevYear;
        preset["baselineUntilDate"] = ValueAdaptor.addDays(prevYear, 1);
        return preset;

      case "last_7days_vs_last_year":
        preset = {
          activePreset: "last_7days_vs_last_year",
          displayType: "spread",
          aggregationBy: "day",
          originalFromDate: ValueAdaptor.addDays(today, -10 - 7),
          originalUntilDate: ValueAdaptor.addDays(today, 10),
          interestFromDate: ValueAdaptor.addDays(today, -7),
          interestUntilDate: today
        };
        preset["baselineFromDate"] = ValueAdaptor.addDays(prevYear, -10 - 7);
        preset["baselineUntilDate"] = ValueAdaptor.addDays(prevYear, 10);
        return preset;

      case "curr_month_vs_last_year":
        let fromDate = ValueAdaptor.addDays(beginningOfMonth, -7);
        let untilDate = ValueAdaptor.addDays(nextMonth, 7);
        let baselineFromDate = ValueAdaptor.addDays(
          ValueAdaptor.addYears(beginningOfMonth, -1),
          -7
        );
        let baselineUntilDate = ValueAdaptor.addDays(
          ValueAdaptor.addYears(nextMonth, -1),
          7
        );

        preset = {
          activePreset: "curr_month_vs_last_year",
          displayType: "spread",
          aggregationBy: "day",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: baselineFromDate,
          baselineUntilDate: baselineUntilDate,
          interestFromDate: beginningOfMonth,
          interestUntilDate: nextMonth
        };

        return preset;

      case "prev_month_vs_last_year":
        fromDate = ValueAdaptor.addDays(prevMonth, -7);
        untilDate = ValueAdaptor.addDays(beginningOfMonth, 7);
        baselineFromDate = ValueAdaptor.addDays(
          ValueAdaptor.addYears(prevMonth, -1),
          -7
        );
        baselineUntilDate = ValueAdaptor.addDays(
          ValueAdaptor.addYears(beginningOfMonth, -1),
          7
        );

        preset = {
          activePreset: "curr_month_vs_last_year",
          displayType: "spread",
          aggregationBy: "day",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: baselineFromDate,
          baselineUntilDate: baselineUntilDate,
          interestFromDate: prevMonth,
          interestUntilDate: beginningOfMonth
        };

        return preset;

      case "last_five_years_vs_last_year":
        fromDate = ValueAdaptor.addYears(
          ValueAdaptor.truncateUTCQuarterDate(yesterday),
          -5
        );
        untilDate = ValueAdaptor.truncateUTCQuarterDate(yesterday);
        preset = {
          activePreset: "last_five_year_vs_last_year",
          displayType: "spread",
          aggregationBy: "quarter",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: ValueAdaptor.addYears(fromDate, -1),
          baselineUntilDate: ValueAdaptor.addYears(untilDate, -1),
          interestFromDate: ValueAdaptor.addYears(new Date(untilDate), -1),
          interestUntilDate: new Date(untilDate)
        };

        return preset;

      case "curr_year_vs_last_year":
        fromDate = ValueAdaptor.truncateUTCYearDate(yesterday);
        fromDate = ValueAdaptor.addMonths(fromDate, -3);
        untilDate = ValueAdaptor.truncateUTCYearDate(yesterday);
        untilDate = ValueAdaptor.addMonths(untilDate, 12 + 4);

        preset = {
          activePreset: "curr_year_vs_last_year",
          displayType: "spread",
          aggregationBy: "month",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: ValueAdaptor.addYears(fromDate, -1),
          baselineUntilDate: ValueAdaptor.addYears(untilDate, -1),
          interestFromDate: ValueAdaptor.truncateUTCYearDate(yesterday),
          interestUntilDate: ValueAdaptor.addYears(
            ValueAdaptor.truncateUTCYearDate(yesterday),
            1
          )
        };

        return preset;

      default:
        return {};
    }
  }

  getButton(name, var_name, value) {
    let callback = () => {
      if (var_name == "preset") {
        let preset = Controler.timePreset(value);
        let newPreset = {
          activePreset: value,
          aggregationBy: preset.aggregationBy,
          displayType: preset.displayType,
          originalFromDate: preset.originalFromDate,
          originalUntilDate: preset.originalUntilDate,
          baselineFromDate: preset.baselineFromDate,
          baselineUntilDate: preset.baselineUntilDate,
          interestFromDate: preset.interestFromDate,
          interestUntilDate: preset.interestUntilDate
        };
        this.currentPreset = newPreset;
        this.setState(newPreset);
        this.props.callback(this.currentPreset);
      } else if (var_name == "displayType") {
        this.currentPreset.displayType = value;
        this.setState({ [var_name]: value });
        this.props.callback(this.currentPreset);
      } else {
        this.setState({ [var_name]: value });
      }
    };
    let isActive = null;
    if (var_name == "preset") {
      isActive =
        this.state.activePreset == value ? "active_button" : "inactive_button";
    } else {
      isActive =
        this.state[var_name] == value ? "active_button" : "inactive_button";
    }
    return (
      <button className={isActive} onClick={callback}>
        {name}
      </button>
    );
  }

  render() {
    return (
      <div className="controler row_direction space_between">
        <div className="selector preset col_direction space_evenly">
          <div className="row_direction space_between">
            {this.getButton("Today", "preset", "today_vs_last_year")}
            {this.getButton("Last 7 days", "preset", "last_7days_vs_last_year")}
            {this.getButton(
              `${ValueAdaptor.toMonthName(
                ValueAdaptor.addMonths(
                  ValueAdaptor.addDays(ValueAdaptor.utcToday(), -1),
                  -1
                )
              )}`,
              "preset",
              "prev_month_vs_last_year"
            )}
          </div>
          <div className="row_direction space_between">
            {this.getButton(
              `${ValueAdaptor.toMonthName(
                ValueAdaptor.addDays(ValueAdaptor.utcToday(), -1)
              )}`,
              "preset",
              "curr_month_vs_last_year"
            )}
            {this.getButton(
              `${ValueAdaptor.toYYYY(
                ValueAdaptor.addDays(ValueAdaptor.utcToday(), -1)
              )}`,
              "preset",
              "curr_year_vs_last_year"
            )}
            {this.getButton(
              "Last 5 Years",
              "preset",
              "last_five_years_vs_last_year"
            )}
          </div>
        </div>

        <div className="selector display_type col_direction space_evenly">
          {this.getButton("Spread", "displayType", "spread")}
          {this.getButton("Cumulated", "displayType", "cumulated")}
        </div>

        <div className="selector date col_direction space_evenly">
          <div className="original row_direction space_between">
            Original:
            <DateSelector
              defaultDate={this.state.originalFromDate}
              callback={x => {
                this.setState({ originalFromDate: x });
              }}
            />
            until
            <DateSelector
              defaultDate={this.state.originalUntilDate}
              callback={x => {
                this.setState({ originalUntilDate: x });
              }}
            />
          </div>
          <div className="baseline row_direction space_between">
            Baseline:
            <DateSelector
              defaultDate={this.state.baselineFromDate}
              callback={x => {
                this.setState({ baselineFromDate: x });
              }}
            />
            until
            <DateSelector
              defaultDate={this.state.baselineUntilDate}
              callback={x => {
                this.setState({ baselineUntilDate: x });
              }}
            />
          </div>
        </div>

        <div className="selector aggregation_by col_direction space_evenly">
          <div className="row_direction space_between">
            {this.getButton("Hour", "aggregationBy", "hour")}
            {this.getButton("Day", "aggregationBy", "day")}
            {this.getButton("Week", "aggregationBy", "week")}
          </div>
          <div className="row_direction space_between">
            {this.getButton("Month", "aggregationBy", "month")}
            {this.getButton("Quarter", "aggregationBy", "quarter")}
            {this.getButton("Year", "aggregationBy", "year")}
          </div>
        </div>

        <div className="selector refresh">
          <button
            className={
              this.state.activePreset == "custom"
                ? "active_button"
                : "inactive_button"
            }
            onClick={() => {
              let newPreset = {
                activePreset: "custom",
                aggregationBy: this.state.aggregationBy,
                displayType: this.state.displayType,
                originalFromDate: this.state.originalFromDate,
                originalUntilDate: this.state.originalUntilDate,
                baselineFromDate: this.state.baselineFromDate,
                baselineUntilDate: this.state.baselineUntilDate,
                interestFromDate: this.state.originalFromDate,
                interestUntilDate: this.state.originalUntilDate
              };
              this.currentPreset = newPreset;
              this.setState(newPreset);
              this.props.callback(this.currentPreset);
            }}
          >
            Custom
          </button>
        </div>
      </div>
    );
  }
}

class KPIChart extends Component {
  buildLineChart() {
    let data = this.props.lineChartData
      .map(e => ({
        date: ValueAdaptor.parseUTCDate(e.date),
        value: e.value == null ? null : Number(e.value),
        baseline: e.baseline == null ? null : Number(e.baseline)
      }))
      .filter(
        e =>
          this.props.displayType != "cumulated" ||
          ((!this.props.interestFromDate ||
            this.props.interestFromDate <= e.date) &&
            (!this.props.interestUntilDate ||
              e.date < this.props.interestUntilDate))
      );

    if (data.length < 2) {
      return null;
    }

    let xValues = data.map(e => e.date);
    let interestFromDate = this.props.interestFromDate || xValues[0];
    let interestUntilDate =
      this.props.interestUntilDate ||
      ValueAdaptor.addDays(xValues[xValues.length - 1], 1);
    let interestData = data.filter(
      e =>
        interestFromDate <= e.date &&
        e.date < interestUntilDate &&
        e.date <
          ValueAdaptor.truncateUTCDateTo(new Date(), this.props.aggregationBy)
    );

    let yToStr = y => ValueAdaptor.toAutoUnit(y, `${this.props.unit || ""}`);
    let xToStr = x =>
      this.props.aggregationBy != "hour"
        ? `${ValueAdaptor.toDDMMYY(x)}`
        : `${x.getUTCHours().toFixed(0)}h`;

    let yValues = data.map(e => e.value);
    let yTotal = interestData.map(e => e.value).filter(x => x != null);
    let yBaseline = data.map(e => e.baseline);
    let yBaselineTotal = interestData
      .map(e => e.baseline)
      .filter(x => x != null);

    if (this.props.aggregationFunc == "avg") {
      yTotal = yTotal.reduce((acc, v) => acc + v, 0) / yTotal.length;
      yBaselineTotal =
        yBaselineTotal.reduce((acc, v) => acc + v, 0) / yBaselineTotal.length;
    } else if (this.props.aggregationFunc == "last") {
      yTotal = yTotal.length > 0 ? yTotal[yTotal.length - 1] : 0;
      yBaselineTotal =
        yBaselineTotal.length > 0
          ? yBaselineTotal[yBaselineTotal.length - 1]
          : 0;
    } else {
      yTotal = yTotal.reduce((acc, v) => acc + v, 0);
      yBaselineTotal = yBaselineTotal.reduce((acc, v) => acc + v, 0);
    }

    if (
      this.props.displayType == "cumulated" &&
      this.props.aggregationFunc != "avg" &&
      this.props.aggregationFunc != "last"
    ) {
      let total = 0;
      for (let i = 0; i < yValues.length; i += 1) {
        total += yValues[i];
        yValues[i] = yValues[i] == null ? null : total;
      }
      total = 0;
      for (let i = 0; i < yBaseline.length; i += 1) {
        total += yBaseline[i];
        yBaseline[i] = yBaseline[i] == null ? null : total;
      }
    }

    const lines = [
      {
        color: Design.getColor(0, 2),
        y: yValues,
        yTotal: yTotal
      },
      {
        color: Design.getBaselineColor(),
        y: yBaseline,
        yTotal: yBaselineTotal
      }
    ];

    return (
      <GrowthLineChart
        name={this.props.title}
        x={xValues}
        yToStr={yToStr}
        xToStr={xToStr}
        lines={lines}
        interestFromX={interestFromDate}
        interestUntilX={interestUntilDate}
        key={this.props.displayType}
      />
    );
  }

  render() {
    let lineChart = this.buildLineChart();
    if (lineChart == null) {
      return <></>;
    }
    return (
      <div className="kpi_wrapper">
        <GrowthResume data={this.props.resumeData} unit={this.props.unit} />
        {lineChart}
      </div>
    );
  }
}
